import "./src/components/global.css";

export function shouldUpdateScroll(prevRouterProps, { location }) {
  window.scrollTo(0, 0);
  const elem = document.getElementById("___gatsby");
  if (elem) {
    elem.scrollTop = 0;
  }
  return false;
}
