module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Y5F39PY5CE"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"杭州美登科技股份有限公司","short_name":"美登科技","description":"杭州美登科技股份有限公司是中国首批电商服务商，致力于成为电商服务领域被信任的领导者，面向淘宝集市和天猫商家提供营销服务，通过数据驱动理性营销，帮助商家节省成本、创造更多价值。","lang":"zh-Hans","display":"standalone","icon":"src/images/icon/favicon.png","start_url":"/","background_color":"#fff","theme_color":"#1d68ff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"afc9d800ee70c8c754f5c407ac7e3714"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
